import * as React from "react"

export default function Wordpress ({ width, className }) {
  return (
    <svg width={width} viewBox="0 0 1663 339" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M944.99 125.8H877.57V132.95C898.66 132.95 902.05 137.47 902.05 164.21V212.42C902.05 239.16 898.66 244.06 877.57 244.06C861.38 241.8 850.45 233.14 835.39 216.56L818.07 197.73C841.42 193.58 853.85 178.9 853.85 162.32C853.85 141.61 836.15 125.79 803.01 125.79H736.72V132.94C757.81 132.94 761.2 137.46 761.2 164.2V212.41C761.2 239.15 757.81 244.05 736.72 244.05V251.2H811.67V244.05C790.58 244.05 787.19 239.15 787.19 212.41V198.85H793.59L835.4 251.2H945C998.86 251.2 1022.21 222.58 1022.21 188.3C1022.21 154.02 998.86 125.78 945 125.78L944.99 125.8ZM787.18 187.19V137.1H802.62C819.57 137.1 827.1 148.77 827.1 162.33C827.1 175.89 819.56 187.19 802.62 187.19H787.18ZM946.12 239.55H943.48C929.92 239.55 928.04 236.16 928.04 218.83V137.1H946.12C985.29 137.1 992.45 165.72 992.45 187.94C992.45 210.16 985.29 239.54 946.12 239.54V239.55Z" fill="white"/>
      <path d="M526.93 201.13L552.92 124.29C560.46 102.07 557.07 95.67 532.96 95.67V88.13H603.77V95.67C580.04 95.67 574.39 101.32 564.98 128.81L522.04 257.62H517.15L478.73 139.73L439.56 257.62H434.66L392.85 128.81C383.81 101.31 377.78 95.67 355.94 95.67V88.13H439.56V95.67C417.34 95.67 411.31 100.94 419.22 124.29L444.45 201.13L482.49 88.13H489.65L526.94 201.13H526.93Z" fill="white"/>
      <path d="M654.98 256.12C613.55 256.12 579.65 225.61 579.65 188.32C579.65 151.03 613.55 120.9 654.98 120.9C696.41 120.9 730.31 151.41 730.31 188.32C730.31 225.23 696.41 256.12 654.98 256.12ZM654.98 132.58C620.33 132.58 608.28 163.84 608.28 188.32C608.28 212.8 620.34 244.07 654.98 244.07C689.62 244.07 702.06 213.19 702.06 188.32C702.06 163.45 690.01 132.58 654.98 132.58Z" fill="white"/>
      <path d="M1115.22 243.69V251.22H1028.97V243.69C1054.21 243.69 1058.72 237.29 1058.72 199.62V139.36C1058.72 101.69 1054.2 95.67 1028.97 95.67V88.13H1106.93C1145.72 88.13 1167.2 108.09 1167.2 134.84C1167.2 161.59 1145.73 181.17 1106.93 181.17H1085.46V199.62C1085.46 237.29 1089.98 243.69 1115.21 243.69H1115.22ZM1106.93 101.69H1085.46V167.23H1106.93C1128.02 167.23 1137.81 152.54 1137.81 134.84C1137.81 117.14 1128.02 101.69 1106.93 101.69V101.69Z" fill="white"/>
      <path d="M1425.95 215.82L1424.07 222.6C1420.68 235.03 1416.53 239.55 1389.8 239.55H1384.52C1364.94 239.55 1361.55 235.03 1361.55 208.29V190.97C1390.93 190.97 1393.19 193.6 1393.19 213.19H1400.35V157.07H1393.19C1393.19 176.66 1390.93 179.29 1361.55 179.29V137.11H1382.26C1409 137.11 1413.15 141.63 1416.53 154.06L1418.42 161.21H1424.44L1421.81 125.81H1311.08V132.96C1332.17 132.96 1335.56 137.48 1335.56 164.22V212.43C1335.56 236.89 1332.68 243.05 1316.05 243.93C1300.24 241.52 1289.39 232.9 1274.55 216.57L1257.22 197.74C1280.57 193.59 1293 178.91 1293 162.33C1293 141.62 1275.3 125.8 1242.16 125.8H1175.87V132.95C1196.96 132.95 1200.35 137.47 1200.35 164.21V212.42C1200.35 239.16 1196.96 244.06 1175.87 244.06V251.21H1250.82V244.06C1229.73 244.06 1226.34 239.16 1226.34 212.42V198.86H1232.74L1274.55 251.21H1429.34L1431.6 215.81H1425.95V215.82ZM1226.34 187.19V137.1H1241.78C1258.73 137.1 1266.26 148.77 1266.26 162.33C1266.26 175.89 1258.72 187.19 1241.78 187.19H1226.34Z" fill="white"/>
      <path d="M1502.4 256.12C1487.33 256.12 1474.15 248.21 1468.5 243.31C1466.62 245.2 1463.23 250.85 1462.48 256.12H1455.32V204.15H1462.85C1465.87 229.01 1483.19 243.7 1505.41 243.7C1517.46 243.7 1527.26 236.92 1527.26 225.62C1527.26 215.83 1518.59 208.29 1503.15 201.14L1481.68 190.97C1466.61 183.81 1455.31 171.38 1455.31 154.81C1455.31 136.73 1472.26 121.29 1495.61 121.29C1508.04 121.29 1518.58 125.81 1524.99 131.08C1526.87 129.57 1528.75 125.43 1529.89 120.91H1537.04V165.36H1529.13C1526.49 147.66 1516.7 132.97 1496.74 132.97C1486.19 132.97 1476.4 139 1476.4 148.41C1476.4 157.82 1484.31 163.47 1502.39 171.76L1523.1 181.93C1541.18 190.59 1548.34 204.53 1548.34 215.83C1548.34 239.56 1527.62 256.13 1502.39 256.13L1502.4 256.12Z" fill="white"/>
      <path d="M1616.29 256.12C1601.22 256.12 1588.04 248.21 1582.39 243.31C1580.51 245.2 1577.12 250.85 1576.37 256.12H1569.21V204.15H1576.74C1579.76 229.01 1597.08 243.7 1619.3 243.7C1631.35 243.7 1641.15 236.92 1641.15 225.62C1641.15 215.83 1632.48 208.29 1617.04 201.14L1595.57 190.97C1580.5 183.81 1569.2 171.38 1569.2 154.81C1569.2 136.73 1586.15 121.29 1609.5 121.29C1621.93 121.29 1632.47 125.81 1638.88 131.08C1640.76 129.57 1642.64 125.43 1643.77 120.91H1650.93V165.36H1643.02C1640.38 147.66 1630.59 132.97 1610.63 132.97C1600.08 132.97 1590.29 139 1590.29 148.41C1590.29 157.82 1598.2 163.47 1616.28 171.76L1636.99 181.93C1655.07 190.59 1662.23 204.53 1662.23 215.83C1662.23 239.56 1641.51 256.13 1616.28 256.13L1616.29 256.12Z" fill="white"/>
      <path d="M169.49 10.17C191 10.17 211.87 14.38 231.5 22.69C240.94 26.68 250.04 31.62 258.56 37.38C267 43.08 274.93 49.63 282.15 56.84C289.36 64.05 295.91 71.99 301.61 80.43C307.36 88.95 312.31 98.05 316.3 107.49C324.61 127.13 328.82 147.99 328.82 169.5C328.82 191.01 324.61 211.88 316.3 231.51C312.31 240.95 307.37 250.05 301.61 258.57C295.91 267.01 289.36 274.94 282.15 282.16C274.94 289.37 267 295.92 258.56 301.62C250.04 307.37 240.94 312.32 231.5 316.31C211.86 324.62 191 328.83 169.49 328.83C147.98 328.83 127.11 324.62 107.48 316.31C98.04 312.32 88.94 307.38 80.42 301.62C71.98 295.92 64.05 289.37 56.83 282.16C49.62 274.95 43.07 267.01 37.37 258.57C31.62 250.05 26.67 240.95 22.68 231.51C14.37 211.87 10.16 191.01 10.16 169.5C10.16 147.99 14.37 127.12 22.68 107.49C26.67 98.05 31.61 88.95 37.37 80.43C43.07 71.99 49.62 64.06 56.83 56.84C64.04 49.63 71.98 43.08 80.42 37.38C88.94 31.63 98.04 26.68 107.48 22.69C127.12 14.38 147.98 10.17 169.49 10.17M169.49 0C75.88 0 0 75.88 0 169.49C0 263.1 75.88 338.98 169.49 338.98C263.1 338.98 338.98 263.1 338.98 169.49C338.98 75.88 263.1 0 169.49 0V0Z" fill="white"/>
      <path d="M28.25 169.49C28.25 225.39 60.74 273.71 107.86 296.6L40.48 112.01C32.64 129.57 28.25 149.01 28.25 169.49V169.49ZM264.84 162.37C264.84 144.91 258.57 132.82 253.2 123.42C246.04 111.78 239.33 101.93 239.33 90.29C239.33 77.31 249.17 65.22 263.05 65.22C263.68 65.22 264.27 65.3 264.88 65.33C239.75 42.31 206.27 28.25 169.5 28.25C120.15 28.25 76.74 53.57 51.49 91.91C54.81 92.01 57.93 92.08 60.58 92.08C75.35 92.08 98.22 90.28 98.22 90.28C105.83 89.83 106.73 101.02 99.12 101.92C99.12 101.92 91.47 102.82 82.96 103.26L134.39 256.25L165.3 163.55L143.3 103.26C135.69 102.81 128.49 101.92 128.49 101.92C120.88 101.47 121.77 89.83 129.39 90.28C129.39 90.28 152.71 92.08 166.59 92.08C181.36 92.08 204.24 90.28 204.24 90.28C211.85 89.83 212.75 101.02 205.14 101.92C205.14 101.92 197.48 102.82 188.98 103.26L240.02 255.09L254.59 208.92C261.06 188.74 264.86 174.45 264.86 162.36L264.84 162.37ZM171.97 181.85L129.59 304.99C142.25 308.71 155.63 310.75 169.49 310.75C185.94 310.75 201.72 307.91 216.4 302.74C216.02 302.14 215.67 301.49 215.39 300.79L171.98 181.85H171.97ZM293.43 101.74C294.04 106.24 294.38 111.06 294.38 116.26C294.38 130.59 291.7 146.7 283.64 166.85L240.5 291.58C282.49 267.1 310.73 221.61 310.73 169.5C310.73 144.94 304.45 121.86 293.43 101.74Z" fill="white"/>
    </svg>
  )
}
